import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
import { Router } from '@angular/router';
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  invokeDashboard = new EventEmitter();    
  invokeDashcomm = new EventEmitter();    
  invokeMonitor = new EventEmitter();    
  invokePages = new EventEmitter();    
  invokeOrderList = new EventEmitter();    
  invokeOrderList2 = new EventEmitter();    
  invokeOrderList3 = new EventEmitter();    
  invokeOrderList4 = new EventEmitter();    
  invokeOrderList5 = new EventEmitter();    
  invokeOrderList6 = new EventEmitter();    
  invokeOrderList7 = new EventEmitter();    
  invokeOrderStat = new EventEmitter();    
  invokeOrderStock = new EventEmitter();    
  invokeOrderConfirm = new EventEmitter();    
  invokeProductStock = new EventEmitter();    
  invokeProductStat = new EventEmitter();    
  invokeCallcenterPre = new EventEmitter();    
  invokeCallcenterPost = new EventEmitter();    
  invokeCallcenterConfirm = new EventEmitter();    
  invokeCallcenterLead = new EventEmitter();    
  invokeLeadList = new EventEmitter();    
  invokeLeadStat = new EventEmitter();   
  invokeWarner = new EventEmitter();    
  invokeConfirmCC = new EventEmitter();    
  invokeGiacenzeCC = new EventEmitter();    
  invokeLeadConversionCC = new EventEmitter();    
  invokeReportGiacenzeCC = new EventEmitter();    

  pages: Subscription;    
  dashboard: Subscription;    
  dashcomm: Subscription;    
  monitor: Subscription;    
  orderlist: Subscription;    
  orderlist2: Subscription;    
  orderlist3: Subscription;    
  orderlist4: Subscription;    
  orderlist5: Subscription;    
  orderlist6: Subscription;    
  orderlist7: Subscription;    
    
  constructor(private router: Router) { }    
    
  onUpdateMenu() {    
	console.log("update Menu");
  }

  onCodsocHeaderComponentButtonClick() {    
	console.log("Codsoc Header Click");
	console.log(this.router.url);
	if (this.router.url.search('dashboard') != -1) {
		this.invokeDashboard.emit();    
	} else if (this.router.url.search('dashcomm') != -1) {
		this.invokeDashcomm.emit();    
	} else if (this.router.url.search('monitor') != -1) {
		this.invokeMonitor.emit();    
	} else if (this.router.url.search('order\/list7') != -1) {
		this.invokeOrderList7.emit();    
	} else if (this.router.url.search('order\/list6') != -1) {
		this.invokeOrderList6.emit();    
	} else if (this.router.url.search('order\/list5') != -1) {
		this.invokeOrderList5.emit();    
	} else if (this.router.url.search('order\/list4') != -1) {
		this.invokeOrderList4.emit();    
	} else if (this.router.url.search('order\/list3') != -1) {
		this.invokeOrderList3.emit();    
	} else if (this.router.url.search('order\/list2') != -1) {
		this.invokeOrderList2.emit();    
	} else if (this.router.url.search('order\/list') != -1) {
		this.invokeOrderList.emit();    
	}
  }
} 
