import {Component, OnInit, ChangeDetectorRef, Inject} from '@angular/core';
import {NbLoginComponent, NB_AUTH_OPTIONS, NbAuthService} from '@nebular/auth';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import hosts from '../../assets/data/host-config.json';
import {Router} from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent extends NbLoginComponent implements OnInit {

  ngOnInit() {
	//this.token.clear();
	console.log("token1 before="+JSON.stringify(localStorage));
    localStorage.removeItem('auth_app_token');
	localStorage.clear();
    console.log("token1="+JSON.stringify(localStorage));
  }

  constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) protected options, cd: ChangeDetectorRef, routes: Router,public http: HttpClient, public globals:Globals) {
	super(service, options, cd, routes);
  }

  public login() {
		var _this = this;
	    this.errors = [];
	    this.messages = [];
	    this.submitted = true;
	    localStorage.removeItem('auth_app_token');
	    localStorage.removeItem('codsoc');
	    localStorage.removeItem('codsocParent');
	    localStorage.clear();
	    //console.log("token2="+JSON.stringify(localStorage));
		
	    console.log(this.strategy);
	    console.log(this.user);
	    this.service.authenticate(this.strategy, this.user).subscribe(function (result) {
				_this.submitted = false;
				
				if (result.isSuccess()) {			
						_this.messages = result.getMessages();
						var token = JSON.parse(localStorage.auth_app_token).value;

						let url = hosts.host+'index.php/getCodSoc/'+token;
						console.log("Url: "+url);
						const options = { headers: new HttpHeaders().set('Authorization', token) };
						var dataq:any;
						dataq = _this.http.get(url,options).subscribe(
							(data:any) => {
							    // Processing for successfull response
								let company = data.data[0].company;
								let codsocen = data.data[0].codsocen;
								let idowner = data.data[0].idowner;
								localStorage.setItem('company',company);
								localStorage.setItem('idowner',idowner);
								localStorage.setItem('codsoc',codsocen);
								localStorage.setItem('codsocParent',codsocen);
								_this.router.navigateByUrl("/pages");
							},
							error => {
							  // Processing for failures
							}
					    );
						//return this.codsocen;
				} else {
						_this.errors = result.getErrors();
				}
				_this.cd.detectChanges();
		});
  }
}
