import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminPagesComponent} from './admin-pages.component';
import { ThemeModule } from '../../../@theme/theme.module';
import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from "@angular/core";
import {
} from '@nebular/theme';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatCheckboxModule} from '@angular/material/checkbox'; 


@NgModule({
  declarations: [AdminPagesComponent],
  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,    
    MatTableModule,    
    MatExpansionModule,
    MatCheckboxModule,
  ],
  exports: [MatExpansionModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA ],
  bootstrap: [AdminPagesComponent]
})
export class AdminPagesModule { }
