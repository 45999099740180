import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbDateService, NbIconLibraries, NbDialogService, NbDatepicker, NbSpinnerModule,} from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Globals } from '../../../globals';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService } from '../../../event-emitter.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  statusValue : string;
  userMenu = [ {title: 'Change Password', link:'/auth/reset-password'},{title: 'Log out', link: '/auth/logout', } ];
  token: string;
  dataq:any;
  owners : any = [];
  show: boolean = false;
  globals:any;
  dataPartenza = new Date();
  dataFine = new Date();
  selectedOwner: string;
  selectedLast: string;
  selectedDal: string;
  selectedAl: string;
  loading = true;


  constructor(private sidebarService: NbSidebarService, globals: Globals, private menuService: NbMenuService,private themeService: NbThemeService,private userService: UserData,private layoutService: LayoutService,private breakpointService: NbMediaBreakpointsService,private dialogService: NbDialogService,public http: HttpClient,private router: Router,private eventEmitterService: EventEmitterService){
    this.globals = globals;
  }

  async ngOnInit() {
    await this.ngOnInitLoad();
  }

  async ngOnInitLoad() {
    this.loading = true;
    this.token = this.globals.getToken();
    this.selectedOwner = await this.globals.getOwner();
    this.selectedLast = await this.globals.getLast();
    this.selectedDal = this.globals.dal;
    this.selectedAl = this.globals.al;
    //init elenco pdv
    let url = this.globals.baseUrl+'index.php/getOwnerAll';
    const options = { headers: new HttpHeaders().set('Authorization', this.token) };
    this.dataq =  await this.http.get(url,options).toPromise(); 
    this.owners = this.dataq.data;
    if(this.owners !== undefined){
      this.owners.unshift({"id":"All","username":"Tutti"}); 
    } else {
      this.owners= [{"id":"All","username":"Tutti"}]; 
    }

    this.userService.getUsers().pipe(takeUntil(this.destroy$)).subscribe((users: any) => this.user = users.nick);
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange().pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl),takeUntil(this.destroy$),)
		.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
    this.loading = false;

  }

  async setOwner(event) {
    this.globals.setOwner(event);
    this.selectedOwner = await this.globals.getOwner();
    //await this.ngOnInitLoad();
    //this.eventEmitterService.onCodsocHeaderComponentButtonClick();
  }

  async setRangeDate(event) {
    this.globals.setRangeDate(event);
    this.selectedDal = this.globals.dal;
    this.selectedAl = this.globals.al;
    //await this.ngOnInitLoad();
    //this.eventEmitterService.onCodsocHeaderComponentButtonClick();
  }

  open(dialog: TemplateRef<any>) {
     this.ngOnInitLoad();	  
     this.dialogService.open(dialog);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  async navigateHome() {
    await this.ngOnInitLoad();
    //this.eventEmitterService.onCodsocHeaderComponentButtonClick();
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
	    this.router.navigate([currentUrl]);
    });
    //this.menuService.navigateHome();
    return false;
  }

}
