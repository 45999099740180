import {Component, OnInit, ChangeDetectorRef, Inject} from '@angular/core';
import {NbLoginComponent, NB_AUTH_OPTIONS, NbAuthService} from '@nebular/auth';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import { Globals } from '../globals';


@Component({
  selector: 'ngx-login',
  templateUrl: './loginGET.component.html',
})
export class NgxLoginGETComponent extends NbLoginComponent implements OnInit {
  constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) protected options, cd: ChangeDetectorRef, routes: Router,public http: HttpClient, public globals:Globals) {
    super(service, options, cd, routes);
    this.globals = globals;
  }

  public getParamValueQueryString( paramName ) {
    const url = window.location.href;        
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
      
  async ngOnInit() {
    //this.token.clear();
    const user:         string = this.getParamValueQueryString("user");
    const password:     string = this.getParamValueQueryString("password");
    const urlToCall:    string = this.getParamValueQueryString("urlToCall");
    const unauthCall:   string = this.getParamValueQueryString("unauth");
    const listID:       string = this.getParamValueQueryString("listID");
    localStorage.removeItem('auth_app_token');    
    this.user.email     = user;
    this.user.password  = password;    
    
    var _this = this;
    this.errors = [];
    this.messages = [];
    this.submitted = true;


    console.log("pre auth");
    if(unauthCall!=null) {
      _this.submitted = false;
      _this.errors = ["Operation not authorized"];      
      console.log("unauth");
    } else {
      console.log("pre auth url "+urlToCall);
      let ttGlobals = this.globals;
      this.service.authenticate(this.strategy, this.user).subscribe(function (result) {
          _this.submitted = false;
  		    console.log("authenticate?");
          if (result.isSuccess()) {
  		      console.log("success");
            let finalUrl = "/pages"+urlToCall;
            _this.messages = result.getMessages();
            _this.router.navigateByUrl(finalUrl);
          } else {
  			    console.log("unsuccess");
            _this.errors = result.getErrors();
          }
        _this.cd.detectChanges();
      });
    }

  }

  public login() {
	  
  }
}
