import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { resolve } from 'url';
import { DatePipe } from '@angular/common';
import hosts from '../assets/data/host-config.json';

@Injectable()
export class Globals {
  urlLogo:string = 'https://dev.infinitech.it/logo.html';
  private codsocen: string;
  status: string = 'All';
  company: string = "Connecting...";
  idowner: any = 0;
  owner: string = 'All';
  public dal: string = '';
  public al: string = '';
  groups: string;

  public baseUrl: string;

  constructor(public http: HttpClient, public datepipe: DatePipe) {
    this.baseUrl = hosts.host;
    console.log(this.baseUrl);
    this.getInfo();
    let dal = new Date();
    let al = new Date(dal.getTime() + 24*60*60*1000);
    dal = new Date(dal.getFullYear(), dal.getMonth()-1, 1);
    this.dal = this.convertDate(dal);
    this.al = this.convertDate(al);
  }

  public setOwner(owner) {
    this.owner = owner;
  }

  public setRangeDate(date) {
    if (date.start != undefined) {
	this.dal = this.convertDate(date.start);
    } else {
    }
    if (date.end != undefined) {
	this.al = this.convertDate(date.end);
    } else {
    }
    console.log(this.dal);
    console.log(this.al);
  }

  public async getOwner() {
	if (this.owner!='All') {
		let url = this.baseUrl+'index.php/getOwner/'+this.owner;
		let token = this.getToken();
		const options = { headers: new HttpHeaders().set('Authorization', token) };
		let dataq:any;
		dataq = await this.http.get(url,options).toPromise();
		if (dataq.data.length>0) {
			return dataq.data[0].cognome+' '+dataq.data[0].nome;
		} else {
			return 'All';
		}
	} else {
		return this.owner;
	}
  }

  public async getUsername() {
	let url = this.baseUrl+'index.php/getOwner/'+this.idowner;
	let token = this.getToken();
	const options = { headers: new HttpHeaders().set('Authorization', token) };
	let dataq:any;
	dataq = await this.http.get(url,options).toPromise();
	console.log(dataq);
	if (dataq.data.length>0) {
		return dataq.data[0].username;
	} else {
		return '';
	}
  }

  public async getLast() {
  let url = this.baseUrl+'index.php/getLastImport';
    let token = this.getToken();
    const options = { headers: new HttpHeaders().set('Authorization', token) };
    let dataq:any;
    dataq = await this.http.get(url,options).toPromise();
    if (dataq.data.length>0) {
        return dataq.data[0].ultimoimport;
    } else {
        return null;
    }
  }

  public async getOwnerGroup(): Promise<string> {
		var token = JSON.parse(localStorage.auth_app_token).value;

		let url = this.baseUrl+'index.php/getOwnerGroup/'+token;
		console.log("myUrl: "+url);

		const options = { headers: new HttpHeaders().set('Authorization', token) };
		var dataq:any;
		dataq = await this.http.get(url,options).toPromise();

		this.groups = dataq.data[0].groups;
		return this.groups;
  }

  public getToken():string {
    var token = JSON.parse(localStorage.auth_app_token).value;
  	return token;
  }

  public convertDate(date){
    date = this.datepipe.transform(date, 'yyyy-MM-dd');
    return date;
  }
  
  public getCodSoc(): string {
	return this.codsocen;
  }

  public getInfo(): string {
                this.codsocen = localStorage.codsoc;
                this.company = localStorage.company;
		this.idowner = localStorage.idowner;
                return this.codsocen;
  }

}


