import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

import { Router } from '@angular/router';

import {Globals} from './globals';

interface Auth {
  message:string;
  status:string;
  role:string;
}

@Injectable({
  providedIn: 'root'
})
export class PageAuthValidatorService {
  router:Router;
  http:HttpClient;
  token:string;
  globals:Globals;

  constructor(http: HttpClient, router: Router, globals: Globals) { this.router = router; this.http = http; this.globals=globals;}

  unauthRedirect() {
    this.router.navigateByUrl('/auth/loginGET?unauth');
  }

  checkPageAuth (page:string):Promise<string>
  { 
		console.log("create token");
		this.token = this.globals.getToken();
		let that = this;
		let url = this.globals.baseUrl+'v3/createTokenB.php/checkPageAuth';

		that.http.post<Auth>(url, {"page":page,"token":that.token} ).subscribe(data => {
				console.log("Check page: "+page+" "+JSON.stringify(data));
				if(!data.role.includes('R'))  
				{
						that.router.navigateByUrl('/auth/loginGET?unauth');              
				} else {
				}
		},error => {
              console.log("Check error "+error);
            }                
        );
		return;
  }
  
}
