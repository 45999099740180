import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Globals } from './globals';

export interface GroupPermission {
  idGroup:number;
  admin:boolean;
  pages:[
      {
        idPage:number;
        role:string;
        idGroupPermission:number;
        roleRead:boolean;        
        roleWrite:boolean;
        roleDelete:boolean;
        page:string;
        description:string;
        changed:string;
        administration:boolean;
      }

  ]
}

export interface User{
  id_owner:number;
  username:string;
  company:string;
  groups:string;
  name:string;
}

export interface Group{
  id_groups:number;
  name:string;
  administrator:number;
}




@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {
  http: HttpClient;
  constructor(http: HttpClient, public globals:Globals) {this.http = http }

  async getGroupPagePermissions():Promise<GroupPermission[]>
  {

    let url = this.globals.baseUrl+'admin_pages.php/getPages';

    var promise = new Promise<GroupPermission[]>((resolve, reject) => {
      
      var token = JSON.parse(localStorage.auth_app_token).value;
      
      const options = { headers: new HttpHeaders().set('Authorization', token) };
      
      this.http.get<GroupPermission[]>(url,options)
        .subscribe(data => {          
          resolve(data);
          return this;
        },
        error => {          
          let obj : GroupPermission[];
          resolve(obj);
          return this;
          
        }  
        );
    });
    return promise;
  }

  async getUserList():Promise<User[]>
  {
    let url = this.globals.baseUrl+'admin_pages.php/getUsers/';

    var promise = new Promise<User[]>((resolve, reject) => {
      
      var token = JSON.parse(localStorage.auth_app_token).value;
      
      const options = { headers: new HttpHeaders().set('Authorization', token) };
      
      this.http.get<User[]>(url,options)
        .subscribe(data => {          
          resolve(data);
          return this;
        },
        error => {          
          let obj : User[];
          resolve(obj);
          return this;
          
        }  
        );
    });
    return promise;
  }

  async getGroups():Promise<Group[]>
  {
    let url = this.globals.baseUrl+'admin_pages.php/getGroups/';

    var promise = new Promise<Group[]>((resolve, reject) => {
      
      var token = JSON.parse(localStorage.auth_app_token).value;
      
      const options = { headers: new HttpHeaders().set('Authorization', token) };
      
      this.http.get<Group[]>(url,options)
        .subscribe(data => {          
          resolve(data);
          return this;
        },
        error => {          
          let obj : Group[];
          resolve(obj);
          return this;
          
        }  
        );
    });
    return promise;
  }


  async getGroupPagePermissionsGroup(groups: string):Promise<GroupPermission[]>
  {
    let url = this.globals.baseUrl+'admin_pages.php/getPagesGroup/'+groups;
    console.log(url);
    
    var promise = new Promise<GroupPermission[]>((resolve, reject) => {
      
      var token = JSON.parse(localStorage.auth_app_token).value;
      
      const options = { headers: new HttpHeaders().set('Authorization', token) };
      console.log("URL: "+url);
      this.http.get<GroupPermission[]>(url,options)
        .subscribe(data => {          
          resolve(data);
          return this;
        },
        error => {          
          let obj : GroupPermission[];
          resolve(obj);
          return this;
          
        }  
        );
    });
    
    return promise;
  }

  isMenuVisible(permissions:GroupPermission[], pageToCheck:string):boolean {
    let ret = false;
    permissions.forEach(elem=>{
      elem.pages.forEach(pg=>{                
        if(pg.page==pageToCheck)
        {          
          if(pg.role.includes("R") || pg.page == "/pages/dashcomm")
          {
            ret = true;
          }
          
          
        }
        
      });
    });
    return ret;
  }

  updatePagePermissions (newStat:GroupPermission[]){
      let url = this.globals.baseUrl+'admin_pages.php/updatePagePermissions/';
      var token = JSON.parse(localStorage.auth_app_token).value;
      const options = { headers: new HttpHeaders().set('Authorization', token) };

      this.http.put(url,newStat,options).subscribe(
        data => {          
                return "success";
                },
        error => {          
                return "error";        
                }  
      );

  }
    

}
