import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import {Globals} from './globals'
import { retry, catchError, tap } from 'rxjs/operators';
import {  throwError } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  globals: Globals;

  constructor(private authService: NbAuthService, private router: Router, globals: Globals) {
	this.globals = globals;
  }

  canActivate() {	
    console.log("AuthGuard canActivate");
	console.log(JSON.stringify(localStorage));
	console.log(allStorage());
	console.log(this.globals);
	this.globals.getInfo();
	return this.authService.isAuthenticated().pipe(tap(authenticated => {
		  if (!authenticated) {
		    this.router.navigate(['auth/login']);
		  } else {
		  }
	  }),
	);
  }

}

function allStorage() {
		var values = [],
		keys = Object.keys(localStorage),
		i = keys.length;
		while ( i-- ) {
				values.push( localStorage.getItem(keys[i]) );
		}
		return values;
}

