import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS,NbAuthService,NbAuthResult,NbResetPasswordComponent } from '@nebular/auth';
import { Globals } from '../globals';
import hosts from '../../assets/data/host-config.json';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NbToastrService,NbComponentStatus  } from '@nebular/theme';

/*@Component({
  selector: 'nb-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  })*/
@Component({
  selector: 'nb-reset-password-page',
  templateUrl: './reset-password.component.html',
})
export class NgxResetPasswordComponent extends NbResetPasswordComponent implements OnInit {

  ngOnInit() {
	console.log(this.user);
	console.log(this.globals);
        /*this.token.clear();
        console.log("token1 before="+JSON.stringify(localStorage));
	localStorage.removeItem('auth_app_token');
        localStorage.clear();
	console.log("token1="+JSON.stringify(localStorage));*/
  }

  constructor(protected service: NbAuthService, @Inject(NB_AUTH_OPTIONS) protected options = {}, protected cd: ChangeDetectorRef,public http: HttpClient, protected router: Router, public globals:Globals, private toastrService: NbToastrService) {
        super(service, options, cd, router);
  }

  async resetPass() {
    var _this = this;
    this.errors = this.messages = [];
    this.submitted = true;
    this.user.username = await this.globals.getUsername();
    var token = JSON.parse(localStorage.auth_app_token).value;

    let url = hosts.host+'index.php/reset-pass';
    console.log("Url: "+url);
    const options = { headers: new HttpHeaders().set('Authorization', token) };
    var dataq:any;
    let body = Object.assign({}, this.user);
    this.http.put<any>(url, body, options).subscribe(
	    (data) => {
		console.log(data);
                let status: NbComponentStatus;
                status = 'success';
                if (data.status == "success"){
                        this.toastrService.show("Password modificata correttamente", data.message, { status });
			//_this.router.navigateByUrl("/pages");
                } else{
                  status = 'danger';
                  this.toastrService.show("Password non modificata", data.message, { status });
                }
              },
              (error) => {
                let status: NbComponentStatus;
                status = "warning";
                this.toastrService.show("ERROR!  ", error, { status });
              });
    this.cd.detectChanges();
  }

}
