import { Component, OnInit } from '@angular/core';
import {Globals} from '../../../globals';
import {AdminServiceService} from '../../../admin-service.service'
import { GroupPermission } from '../../../admin-service.service';
import { servicesVersion } from 'typescript';
import { EventEmitterService } from '../../../event-emitter.service';
import {PageAuthValidatorService} from "../../../page-auth-validator.service";


@Component({
  selector: 'ngx-admin-pages',
  templateUrl: './admin-pages.component.html',
  styleUrls: ['./admin-pages.component.scss']
})


export class AdminPagesComponent implements OnInit {

  
  displayedColumns = ["page","read","write","delete"];
  dataQ: GroupPermission[];
  
  globals: Globals;
  permissions:GroupPermission;
  constructor(globals: Globals, public myService:AdminServiceService, public eventEmitterService: EventEmitterService , public authCheck: PageAuthValidatorService) {
        this.globals = globals;
  }

  async ngOnInit() {
    this.dataQ = await this.myService.getGroupPagePermissions();
    let ret = await this.authCheck.checkPageAuth("/pages/admin/admin-pages");
  }

  save($event) {
    this.myService.updatePagePermissions(this.dataQ);
    this.eventEmitterService.onUpdateMenu();    

    

  }

  valueChange(idGroup:string,idPage:string,idGroupPermission:string, type:string, $event)
  {
      this.dataQ.forEach(function (group){
        group.pages.forEach(function (page){

          if(page.idPage == parseInt(idPage) && group.idGroup == parseInt(idGroup))
          {
            let r = false;
            let w = false;
            let d = false;
            if(page.role.includes("R")){
              r = true;
            }
            if(page.role.includes("W")){
              w = true;
            }
            if(page.role.includes("D")){
              d = true;
            }
            let oldValue= ((r==true)?"R":"")
                          +((w==true)?"W":"")
                          +((d==true)?"D":"");

            let now = false;
            
            
            if($event.checked)
            {
              now = true;
            }
            if(type=="R")
            {
              r = now;
            }
            if(type=="W")
            {
              w = now;
            }
            if(type=="D")
            {
              d = now;
            }

            let newValue = ((r==true)?"R":"")
                          +((w==true)?"W":"")
                          +((d==true)?"D":"");

            
            page.role = newValue;
            page.changed = "true";
          }
        });

      });
  }




}
