import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AdminRolesComponent } from './admin-roles.component';
import { CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from "@angular/core";
import { NbIconModule, } from '@nebular/theme';
import { ThemeModule } from '../../../@theme/theme.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormsModule } from '@angular/forms';
@NgModule({
  
  imports: [
    ThemeModule,
    FormsModule,
    Ng2SmartTableModule,
    NbIconModule,
    NbEvaIconsModule
  ],
  declarations: [AdminRolesComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA ],
  bootstrap: [AdminRolesComponent],  

})

export class AdminRolesModule{ }
