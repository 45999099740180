import { Directive,Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class SubtableDirective {
  @Input() matchCellHeight: any;

  constructor(private el: ElementRef) { }

}
