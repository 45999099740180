import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { NgModule } from '@angular/core';
import { NgxLoginComponent } from './login/login.component';
import { NgxResetPasswordComponent, } from './login/reset-password.component';
import { NgxLoginGETComponent } from './loginGET/loginGET.component';


import {
  NbAuthComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
} from '@nebular/auth';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'loginGET',
    component: NgxLoginGETComponent,
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent,
      },
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },      
      /*
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      */
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NgxResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
