import { Component, OnInit } from '@angular/core';
import {AdminServiceService, Group, User} from '../../../admin-service.service';
import { LocalDataSource } from 'ng2-smart-table';
import {Globals} from '../../../globals';
import {PageAuthValidatorService} from "../../../page-auth-validator.service";



@Component({
  selector: 'ngx-admin-roles',
  templateUrl: './admin-roles.component.html',  
})

export class AdminRolesComponent implements OnInit {
  usersQ: User[] = [];
  groupsQ: Group[] = [];
  
  globals: Globals;
  

  settings = {
    mode: 'external',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,

    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    columns: {
      username: {
        title: 'User name',
      },
      company: {
        title: 'Company',
      },
      /*company_descr:  {
        title: 'Company descr.',
      },      
      phone:  {
        title: 'Phone',
      },      
      fax :   {
        title: 'Fax',
      },    
      address:   {
        title: 'Address',
      },    
      city:   {
        title: 'City',
      },    
      zip_code:   {
        title: 'Zip code',
      },    
      province:   {
        title: 'Province',
      },    
      vat_number:   {
        title: 'Vat number',
      },    
      tax_code:   {
        title: 'Tax code',
      },    
      mobile:   {
        title: 'Mobile',
      },    
      email:   {
        title: 'Email',
      },    
      email_cc:   {
        title: 'Email',
      },    
      email_ccn:   {
        title: 'Email CCN',
      },    
      mobile_customer:   {
        title: 'Mobile customer',
      },    
      operatore: {
        title: 'Operator',
      },          
      password:    {
        title: 'Password',
      },
      codsoc:  {
        title: 'Cod Soc.',
      },    
      stati_gruppo:  {
        title: 'Group states',
      },    
      email_call:  {
        title: 'Email call',
      },    
      email_log:  {
        title: 'Email log',
      },    
      vtiger_url: {
        title: 'URL Vtiger',
      },    
      vtiger_usr:  {
        title: 'User Vtiger',
      },    
      vtiger_pwd:  {
        title: 'Pwd Vtiger',
      },*/   
      name: {
        title: 'Group',
      },      
    },
    pager: {
      perPage: 30
    },
    actions: { 
      delete: true,
      add: true,
    },
  };

  constructor(globals: Globals,public myService:AdminServiceService, public authCheck: PageAuthValidatorService,) { 
    

  }

  async ngOnInit() {

    let that = this;
    this.usersQ = await this.myService.getUserList();
    this.groupsQ = await this.myService.getGroups();

    let ret = await this.authCheck.checkPageAuth("/pages/admin/admin-roles");
    
  }

}
